import React, {useEffect, useLayoutEffect, useState} from "react";
import {HomePageSignUp} from "../components/home-page-sign-up";
import Page from "../components/page";
import UnatyLogo from "../assets/UnatyLogo";
import styled from "styled-components";
import {ChevronRightIcon} from "@heroicons/react/solid";
import {changelog} from "./whats-new";
import {AnchorLink} from "../components/menu";

const USPIcon = styled.div`
  > svg {
    width: 40px !important;
    height: 40px !important;
  }
`;

const config = {
    page_title: 'Organize your Community on Unaty',

    headline: ['Unaty helps', 'your community', 'get organized.'],

    sub_headline: [
        {
            text: 'The only community management platform',
            class: ''
        },
        {
            text: 'designed to help leaders and members',
            class: ''
        },
        {
            text: 'spend more time together offline.',
            class: 'underline italic'
        }
    ],

    beta_message: 'Unaty is currently in early access.',

    image: '/assets/images/new-home-screen.jpg',

    images: [
        '/assets/images/new-home-screen.jpg',
        '/assets/images/baseball.jpg',
        '/assets/images/washington.jpg',
        '/assets/images/sba.jpeg',
        '/assets/images/mlk.jpg'
    ],

    caption: 'We believe software should work hard so you can spend time on what really matters.',

    benefits: [
        'manages member data proactively',
        'eliminate repetitive administrative tasks',
        'empowers everyday leaders'
    ],

    usps: [
        {
            icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
            </svg>,
            title: 'Easy for everyone',
            subtitle: '... description'
        },
        {
            icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"/>
            </svg>,
            title: 'Own your data',
            subtitle: '... description'
        },
        {
            icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z"/>
            </svg>,
            title: 'Powerful automation',
            subtitle: 'roles/tools/email'
        },
        {
            icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"/>
            </svg>,
            title: 'customizable',
            subtitle: '... description'
        }
    ],

    features: [
        {
            title: 'Modern Member Data Management',
            subtitle: 'This is how it works',
            image: '/assets/images/members-directory-screenshot.png',
            benefits: [
                {
                    icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>,
                    title: 'data quality scores',
                    subtitle: 'it does this thing'
                },
                {
                    icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>,
                    title: 'bottom-up member managament',
                    subtitle: 'it does this thing'
                }
            ]
        },
        {
            title: 'Group Email Lists',
            subtitle: 'This is how it works',
            image: '/assets/images/members-directory-screenshot.png',
            benefits: [
                {
                    icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>,
                    title: 'always-up-to-date',
                    subtitle: 'it does this thing'
                },
                {
                    icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>,
                    title: 'entity-based',
                    subtitle: 'it does this thing'
                }
            ]
        }
    ]
};

/*

                            <Typing onFinishedTyping={() => increment()} key={benefit}
                                    speed={20} hideCursor>
                                {config.benefits[benefit]}
                                <Typing.Backspace key={benefit} speed={20} delay={5000}
                                                  count={config.benefits[benefit].length}/>
                            </Typing>
 */

const NewHomePage = ({}) => {
    const [image_dims, setImageDims] = useState({height: '100%', width: '100%'});
    const [benefit, setBenefit] = useState(0);
    const [image, setImage] = useState(0);

    function increment() {
        const len = config.benefits.length;
        if ((benefit + 1) === len) {
            setBenefit(0);
        } else {
            setBenefit(benefit + 1);
        }
    }

    function updateSize() {
        if(window) {
            const el = document.getElementById('main-image');

            if (!el) {
                return;
            }

            const rect = el.getBoundingClientRect();

            const screen_width = window.innerWidth;

            // 16 = the split between left and right
            // 20 for right padding
            const width = (screen_width / 2) - 16 - 20;

            if (screen_width < 768) {
                setImageDims({height: '25vh', width: '100%'});
            } else {
                setImageDims({height: '100%', width});
            }
        }
    }

    useLayoutEffect(function () {
        if(window) {
            window.addEventListener('resize', updateSize);
        }
        if(window) {
            updateSize();
        }
        return () => {
            if(window) {
                window.removeEventListener('resize', updateSize);
            }

        }
    }, []);

    return <Page menu title={config.page_title}>

        <div className="mx-auto relative max-w-7xl flex px-4 pt-16 pb-6" style={{minHeight: '90vh'}}>
            <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-8 flex-grow">
                <div className="flex items-center flex-grow md:flex-grow">
                    <div className="pb-24">
                        <div className="hidden md:block mb-8">
                            <AnchorLink to="/whats-new"
                                        className="inline-flex cursor-pointer hover:opacity-80 transition-opacity space-x-4">
                  <span
                      className="rounded bg-green-50 px-2.5 py-1 text-xs font-semibold text-green-700 tracking-wide uppercase">
                    What's new
                  </span>
                                <span className="inline-flex items-center text-sm font-medium text-green-700 space-x-1">
                    <span>Just shipped version {changelog[0].v}</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                  </span>
                            </AnchorLink>
                        </div>
                        <div className="text-5xl md:text-6xl mb-4 text-gray-800 font-cheltenham">
                            {config.headline.map(line => <span className="block" key={line}>{line}&nbsp;</span>)}
                        </div>
                        <div className="mb-6 leading-normal text-gray-600 text-base md:text-xl">
                            {config.sub_headline.map((line, k) => <span className={`block ${line.class}`}
                                                                        key={line.text}>{line.text}{k === config.sub_headline.length ? "" : " "}</span>)}
                        </div>
                        <div>
                            <HomePageSignUp/>
                        </div>
                        <div className="text-sm text-gray-700 pt-2">
                            {config.beta_message}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex-grow flex flex-col">
                        <div onClick={()=>{
                            const len = config.images.length;

                            if((image+1)===len) {
                                setImage(0);
                            } else {
                                setImage(image+1);
                            }
                        }} id="main-image" className="h-20 w-20 flex-grow shadow-md rounded bg-center bg-cover"
                             style={{backgroundImage: `url(${config.images[image]})`, ...image_dims}}>

                        </div>
                    </div>
                    <div className="flex-none pt-1.5 px-1">
                        <div className="italic text-gray-500 text-xs md:text-sm">
                            {config.caption}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pt-16 pb-12 px-6">
            <div className="mx-auto relative max-w-7xl">
                <div className="mb-16">
                    <div
                        className="mx-auto w-full border-2 border-gray-400 -mt-8 max-w-2xl rounded-md shadow-md h-96 bg-gray-50">
                        <iframe className="rounded" src={"https://www.loom.com/embed/98947236989e42e4b20a314935b959c3"}
                                frameBorder="0"
                                webkitAllowFullscreen mozAllowFullscreen allowFullScreen
                                style={{width: "100%", height: "100%"}}></iframe>
                    </div>
                </div>
                <div className="mb-8">
                    <div className="text-center text-3xl space-y-1 font-bold text-gray-800">
                        <div>
                            Simple and smart tools that
                        </div>
                        <div className="-my-0.5 -mx-2 rounded-md py-0.5 px-2 bg-yellow-100 inline-flex ">
                            &nbsp;
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className="max-w-4xl mx-auto grid grid-cols-1 gap-6 md:grid-cols-2">
                    {config.usps.map((usp, i) => <div className="border border-gray-200 mb-2 py-4 px-5 rounded" key={i}>
                        <USPIcon className="text-gray-800 mb-2">
                            {usp.icon}
                        </USPIcon>
                        <div className="text-xl text-gray-800 font-semibold">
                            {usp.title}
                        </div>
                        <div className="text-base text-gray-600">
                            {usp.subtitle}
                        </div>
                    </div>)}

                </div>
            </div>
        </div>
        {config.features.map((ft, k) => <div className="py-12 px-4" key={k}>
            <div className="mx-auto relative max-w-5xl">
                <div className="mb-4">
                    <div className={"text-3xl pb-1 font-bold text-gray-800"}>{ft.title}</div>
                    <div className={"text-lg text-gray-600"}>{ft.subtitle}</div>
                </div>
                <div className="mb-6">
                    <img className="rounded shadow w-10/12" src={ft.image}/>
                </div>
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {ft.benefits.map((bn, i) => <div>
                        <USPIcon className="text-gray-800 mb-2">
                            {bn.icon}
                        </USPIcon>
                        <div className="text-xl text-gray-800 font-semibold">
                            {bn.title}
                        </div>
                        <div className="text-base text-gray-600">
                            {bn.subtitle}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>)}
    </Page>
}

export default NewHomePage;